<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">财务模块</el-breadcrumb-item>
        <el-breadcrumb-item>收支明细</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="90px" :model="query" ref="query" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col style="width: 140px">
            <el-input v-model="query.keyword" @keyup.enter="fetchs()" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="公司" label-width="80px">
          <el-tree-select ref="companys" v-model="companyId" :data="companys" clearable
            @change="departmentId = ''; companyChange();" check-strictly :render-after-expand="false" style="min-width: 220px;"  />
        </el-form-item>

        <el-form-item label="部门" prop="departmentId" label-width="60px">
          <el-tree-select ref="departments" v-model="departmentId" :data="departments" clearable check-strictly
            @change="fetchs()" :render-after-expand="false" style="min-width:120px" />
        </el-form-item>

        <el-form-item label="时间范围" label-width="120px">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <!--<el-button plain type="primary" icon="plus" @click="openBillDialog">新增费用单</el-button>-->
      <el-button plain type="info" icon="download" @click="download">导出明细</el-button>
    </div>

    <basic-table ref="table" class="table" :query="query" @handler="fetchs">
      <template v-slot:billCode="scope">
        <el-link type="primary" size="small" :underline="false"
          @click="$router.push('/workflow/flowForm?id=' + scope.row.id)">{{ scope.row.billCode }}</el-link>
      </template>
      <template v-slot:status="scope">
        <span style="color:blue;" v-if="scope.row.status == 1">已匹配银行流水</span>
        <span style="color:red;" v-if="scope.row.status == -1">无流水</span>
        <span style="color:gray;" v-else>未匹配到流水</span>
      </template>
    </basic-table>
  </el-card>

  <static-dialog ref="billDialog" class="dialog" title="费用单详情" :ok="submitBills" width="950">
    <el-form :model="forms" ref="forms" :rules="rules" :label-width="110">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="公司名称" prop="companyId" style="width: 95%">
            <el-tree-select ref="companys" v-model="companyId" :data="companys" clearable @change="companyChange()"
              check-strictly :render-after-expand="false" style="width:300px" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="分摊部门" prop="departmentIds" style="width: 95%">
            <el-tree-select ref="departmentIds" v-model="departmentIds" style="width: 100%" @change="departmentChange"
              :data="departments" multiple collapse-tags-tooltip filterable :render-after-expand="false"
              placeholder="可多选部门" />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="费用名称" class="flex " style="width: 600px">
          </el-form-item>
        </el-col> -->
        <el-col :span="24">
          <el-form-item label="费用用途" prop="billName">
            <el-input v-model="forms.billName"><template #prepend>费用分摊单据</template></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="整体支出" prop="expenseFee">
            <el-input-number v-model="forms.expenseFee" :min="-10000000" :max="10000000"
              placeholder="0.00"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用日期" prop="billDate" style="width: 250px">
            <el-date-picker v-model="forms.billDate" type="date" />
          </el-form-item>
        </el-col>
      </el-row>
      <hr>
      <el-col :span="24" v-for="(item, index) in details">
        <el-form-item :label="'分摊部门' + (index + 1)">
          <el-col :span="6">
            <el-input v-model="item.departmentName" style="width:150px;" disabled></el-input>
          </el-col>
          <el-col :span="9">
            分摊金额：
            <el-input-number v-model="item.expenseFee" :min="-10000000" :max="10000000"
              placeholder="0.00"></el-input-number>
          </el-col>
          <el-col :span="8">
            单号：<el-input v-model="item.billCode" style="width: 220px" autocomplete="on"></el-input>
          </el-col>
        </el-form-item>
      </el-col>
    </el-form>
  </static-dialog>

</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import BasicTable from "@/components/basic-table.vue";
import { getBillCode, submitBatch } from "@/api/fund";
export default {
  name: "FundBillLog",
  components: { StaticDialog, BasicTable },
  data() {
    return {
      query: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { type: "template", prop: "billCode", label: "单据号", width: "210", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "billDate", label: "账单时间", width: "145", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "companyName", label: "公司名称", width: "140", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "departmentName", label: "部门名称", width: "90", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "billName", label: "用途", width: "150", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "formName", label: "申请单名称", width: "130", sortable: true, searchable: true, showOverflowTooltip: true, },

          { prop: "revenueFee", label: "收入金额", width: "90", sortable: true, },
          { prop: "expenseFee", label: "支出金额", width: "90", sortable: true, },

          { type: "template", prop: "status", label: "状态", width: "120", sortable: true, showOverflowTooltip: true, },

          { prop: "createDate", label: "创建时间", width: "140", sortable: true, },
        ],
        filters: [],
        sidx: "billDate",
        sord: "desc"
      },
      dateRange: [],

      companyId: '',
      companys: [],
      departmentId: '',
      departments: [],
      departmentIds: null,

      forms: {},
      details: [],
      rules: {
        // departmentIds: [
        //   //{ message: '请至少选择一个分摊的部门', type: 'array', min: 1, max: 5 }
        //   { required: true, message: '请至少选择一个分摊的部门', trigger: 'change' }
        // ],
        // formName: [
        //   { required: true, message: "请输入分摊的费用名称", trigger: "blur" },
        // ],
        billName: [
          { required: true, message: "请输入分摊的费用用途", trigger: "blur" },
        ],
        expenseFee: [
          { required: true, message: "请输入正确的费用金额", trigger: "blur" },
        ],
        billDate: [
          { required: true, message: "请选择分摊的费用日期", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    fetchs() {
      this.query.filters = [];
      if (this.dateRange && this.dateRange[0]) {
        this.query.filters.push({ name: "billDate", value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"), operate: 2 });
      }
      if (this.dateRange && this.dateRange[1]) {
        this.query.filters.push({ name: "billDate", value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59", operate: 4 });
      }
      if (this.companyId && this.companyId.length > 0) {
        this.query.filters.push({ name: "companyId", value: this.companyId, operate: 6, });
      }
      if (this.departmentId && this.departmentId.length > 0) {
        this.query.filters.push({ name: "departmentId", value: this.departmentId, operate: 6, });
      }

      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.search(params).then((res) => {
        this.query = { ...res.data };
      });
    },

    companyChange() {
      this.departmentId = '';
      this.departmentIds = null;
      var that = this;
      if (!this.companyId) {
        that.fetchs();
        return;
      }

      this.$kaung.departments(this.companyId).then((res) => {
        that.departments = res;
        that.fetchs();
      });
    },
    departmentChange() {
      let nowDateTimeStr = this.$util.fmtDate(new Date(), "HHmmss");
      this.details = [];
      this.departmentIds = this.departmentIds || [];
      this.departmentIds.forEach((departmentId) => {
        let departmentName = this.$refs.departmentIds.getNode(departmentId).data.departmentName;
        //let items = this.departments.filter(item => item.id == departmentId);
        this.details.push({
          "departmentId": departmentId, "departmentName": departmentName,
          "revenueFee": 0, "expenseFee": 0,
          'billCode': this.forms.billCode + nowDateTimeStr
            + '-' + (this.forms.billNumber + this.details.length).toString().padStart(3, '0')
        })
      });
    },

    openBillDialog() {
      this.forms.companyId = this.companyId;

      getBillCode().then((res) => {
        console.log(res);
        this.forms.billCode = 'FYFT' + '-' + res.data.billDate;
        this.forms.billNumber = res.data.billNumber;
      });
      this.$refs.billDialog.toggle();
    },
    submitBills() {
      let that = this;

      if (this.departmentIds.length == 0) {
        that.$message('请至少选择一个分摊的部门');
        return;
      }

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        let expenseFee = 0.00;
        this.details.forEach((item, index) => {
          item.billDate = this.$util.fmtDate(that.forms.billDate, "yyyy-MM-dd");
          item.billName = that.forms.billName;
          item.formName = '费用分摊单据';
          item.companyId = that.companyId;
          item.companyName = that.$refs.companys.getNode(that.companyId).data.companyName;
          //item.billCode = that.forms.billCode + '-' + (this.forms.billNumber++ + '').padStart(3, '0');

          expenseFee = expenseFee + parseFloat(item.expenseFee + '');
        });
        if (expenseFee.toFixed(2) != this.forms.expenseFee.toFixed(2)) {
          that.$message('分摊金额合计不等于总支出金额，请检查金额');
          return;
        }
        submitBatch(this.details).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });

            that.$refs.billDialog.toggle();
            that.fetchs();
          }
        });
      });
    },
    download() {
      this.query.filters = [];
      if (this.dateRange && this.dateRange[0]) {
        this.query.filters.push({ name: "billDate", value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"), operate: 2 });
      }
      if (this.dateRange && this.dateRange[1]) {
        this.query.filters.push({ name: "billDate", value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59", operate: 4 });
      }
      if (this.companyId && this.companyId.length > 0) {
        this.query.filters.push({ name: "companyId", value: this.companyId, operate: 6, });
      }
      if (this.departmentId && this.departmentId.length > 0) {
        this.query.filters.push({ name: "departmentId", value: this.departmentId, operate: 6, });
      }

      let { data, records, total, ...params } = { ...this.query };
      
      this.$kaung.export(params).then((res) => {
        this.$notify({
          title: '表格数据导出',
          message: res.content,
          type: res.type,
          duration: 10000
        });
        window.location.href = process.env.VUE_APP_BASEURL + res.data;
      });
    },
  },
  watch: {
  },
  created: function () {
  },
  mounted() {
    //kaung插件初始化
    this.$kaung.init(this.$options.name);

    this.companyId = this.$util.getLocalstorage('companyIdStr') || '';
    this.departmentId = this.$route.query.departmentId || '';

    this.dateRange = JSON.parse(this.$route.query.dateRange || '[]');
    if (this.dateRange.length == 0) {
      this.dateRange = this.$util.getCurrentDate();
    }

    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.companyChange();
    });
  },
};
</script>
<style lang="scss" scoped></style>